<template>
  <div v-if="organizationId">
    <OrganizationAbout
      class="card-body"
      :organization="organization"
    ></OrganizationAbout>
    <ScorecardIndicators class="card-body"></ScorecardIndicators>
    <Projects class="card-body"></Projects>
    <BigBendPhotoObservations v-if="organizationId === 'big-bend-restoration' && Object.keys(projects).length > 0"></BigBendPhotoObservations>
    <OtherHubsPhotoObservations v-else-if="organizationId !== 'big-bend-restoration' && Object.keys(projects).length > 0" class="card-body"></OtherHubsPhotoObservations>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ScorecardIndicators from "./ScorecardIndicators.vue";
import OrganizationAbout from "./about";
import Projects from "./projects";
import BigBendPhotoObservations from "./bigBendPhotoObservations.vue";
import OtherHubsPhotoObservations from "./otherHubsPhotoObservations.vue";

export default {
  components: {
    OrganizationAbout,
    Projects,
    ScorecardIndicators,
    BigBendPhotoObservations,
    OtherHubsPhotoObservations
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      organization: "organization/get/organization",
      organizationId: "organization/get/id",
      projects: "organization/get/projects"
    })
  }
};
</script>

<style lang="scss" scoped>
.score-card {
  @media screen and (max-width: 1050px) {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
}

.section {
  width: 100%;

  &.bg-green {
    background: #869740ff;

    & > .section-title {
      color: #fff;
    }
  }

  &.featured-project {
    @media screen and (max-width: 1050px) {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
  }
}
</style>
