import get from './get'
import list from './list'
import create from './create'
import update from './update'
import show from './show'
import del from './delete'
import projectDownload from './projectDownload'
import assigned_locations from "./assigned_locations";
import manageMembers from './manageMembers'
import resources from './resources'
import results from './results'
import images from './images'
import photo_observations from "./photo_observations";
import invites from "./invites";
import forum from "./forum";
import members from './members';
import locations from "./locations";

export default {
  namespaced: true,
  modules: {
    get,
    list,
    create,
    update,
    show,
    del,
    projectDownload,
    assigned_locations,
    manageMembers,
    resources,
    results,
    images,
    photo_observations,
    invites,
    forum,
    members,
    locations,
  }
}
