import VueLoader from "../../../components/layout/Loader";
import VueGoodshare from "vue-goodshare";
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
// import VueGoogleMap from "../../../components/layout/GoogleMap";
import DatePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import { mapActions, mapGetters } from "vuex";
import VueSocialSharing from "vue-social-sharing";
import { BCarousel } from "bootstrap-vue";
import ObservationDateTime from '../../../components/observation/ObservationDateTime.vue'
import ObservationRecordList from "../../../components/observation/ObservationRecordList"

export default {
  components: {
    VueLoader,
    VueGoodshare,
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    // VueGoogleMap,
    DatePicker,
    VueSocialSharing,
    BCarousel,
    ObservationDateTime,
    ObservationRecordList
  },

  provide() {
    return { parentValidator: null };
  },

  props: {
    isNewAutoAssign: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    
    let tableItems = [];
    let user = JSON.parse(localStorage.getItem("sessionData"));

    return {

      mapOptions:{
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        zoomControl: true,
        mapTypeControl: true
      },
      locationData: null,
     
      showNewAssignAlert: this.isNewAutoAssign,
      options: {
        format: "YYYY/MM/DD h:mm A",
        showClose: true
      },
      locationModel: {
        latitude: "",
        longitude: "",
        locationName: ""
      },
      twitterProjectName: "",
      facebookProjectName: "",
      editField: {
        isLocationEdit: false
      },
      showEditDate: false,
      slide: 0,
      sliding: null,
      deleteRecordId: 0,
      orderDeleted: -1,
      recordImage: null,
      previewNewRecordImage: null,
      previewEditRecordImage: null,
      recordIndex: -1,
      parentValidator: null,
      isLoadingShortUrl: true,
      questions: [],
      currentUrl: "/",
      selected: null,
      textInputVal: "",
      editDateValue: "",
      showAddModeData: false,
      addMoreDataQ1: 1,
      addMoreDataMN: "",
      addMoreDataValueType: "",
      addMoreDataValueTypeList: [
        { value: "", text: "--Select--" },
        { value: "number", text: "Numerical" },
        { value: "text", text: "Text (Single Line)" },
        { value: "textarea", text: "Text (Multiline)" },
        { value: "datetime", text: "Date and time" },
        { value: "radio", text: "Radio" },
        { value: "dropdown", text: "Dropdown" },
        { value: "image", text: "Image" }
      ],
      addMoreDataValue: "",
      addMoreDataUnits: "",
      textAreaValue: "",
      dateValue: "",
      showForm: true,
      loggedInUser: user,
      lat: 45,
      lng: 27,
      tableItems: [],
      edit: false,
      displayValue: false,
      displayUnits: false,
      displayText: false,
      displayDateTime: false,
      displayImage: false,
      displayRadio: false,
      displayDropdown: false,
      images: [],
      bigImageUrl: "/assets/images/generic_img.png",
      files: "",
      recordImageUpload: null,
      dropDownList: [
        {
          fieldVal: ""
        }
      ],
      chooseList: [
        {
          fieldVal: ""
        }
      ],
      editActive: false
    };
  },

  computed: {
    ...mapGetters({
      isLoading: "observation/show/isLoading",
      isLoadingLocationUpdate: "location/show/isLoading",
      item: "observation/show/retrieved",
      error: "observation/show/error",
      deletedSuccess: "observation/show/deletedSuccess",
      userData: "user/profile/sessionData"
    }),
    canEdit() {
      if (this.item && this.item.project) {
        return (
          this.isProjectRoleManager(this.item.project.id) ||
          this.isObserver(this.item)
        );
      } else {
        return false
      }
    },
    observedAtFormated() {
      if (this.item && this.item.observedAt) {
        return this.getDisplayDateTime(this.item.observedAt);
      }
      return "";
    }
  },

  watch: {
    /**
     * @function item - function that is called when this.item is changed
     * @param newValue
     */
    item(newValue) {
      let current = {};
      let firstSelected = false;

      if (this.images.length === 0) {
        firstSelected = true;
      }

      this.images.forEach((item, index) => {
        if (item.current) {
          current = item;
        }
      });

      this.images = [];

      newValue.files.forEach((item, index) => {
        if (item.file.path === current.path) {
          //this.images[0].current=true;
          this.images.push({
            path: item.file.path,
            current: true
          });
        } else {
          //this.images[0].current=false;
          this.images.push({
            path: item.file.path,
            current: false
          });
        }
      });

      if (firstSelected && this.images.length > 0) {
        this.images[0].current = true;
      }

      if (newValue.files.length === 0) {
        this.images = [];
      }
    },

    /**
     * listen to item.records changes
     * @param newValue
     */
    "item.records"(newValue) {
      if (
        typeof newValue != "undefined" &&
        newValue instanceof Array &&
        newValue.length > 0
      ) {
        this.questions = [];

        newValue.forEach((recordItem, recordIndex) => {
          if (recordItem.recordType === "organism") {
            this.questions.push({
              questionId: recordItem.id,
              question_text: this.getOrganismName(recordItem.organism),
              type: recordItem.recordType,
              edit: false,
              answers: [],
              subRecords: []
            });

            recordItem.records.forEach(
              (organismRecordItem, organismRecordIndex) => {
                let subRecord = this.prepareForDisplayRecords(
                  organismRecordItem
                );
                if (subRecord){
                  this.questions[this.questions.length - 1].subRecords.push({
                    observation__field: subRecord.question_text,
                    observation__answer: subRecord
                  });
                }

              }
            );
          } else if (recordItem.parentRecord !== null) { // all records that are not organisms & which have a parent record
              let newRecord = this.prepareForDisplayRecords(recordItem);
              if (newRecord){
                this.questions.push(this.prepareForDisplayRecords(recordItem));
              }
          }
        });

        this.tableItems = [];
        this.questions.forEach((questionItem, questionIndex) => {
          let answer = "";

          if (
            ["radio", "dropdown", "organism"].indexOf(questionItem.type) !== -1
          ) {
            questionItem.answers.forEach((answerItem, answerIndex) => {
              if (answerItem.selected) {
                answer = answerItem.value;
              }
            });
          } else {
            if (questionItem.answers && questionItem.answers.length > 0) {
              answer = questionItem.answers[0].value;
            }
          }

          this.tableItems.push({
            observation__field: questionItem.question_text,
            observation__answer: questionItem
          });
        });
      }
    },

    /**
     * @function addMoreDataQ1 - function that is called when this.addMoreDataQ1 is changed
     * @param value
     */
    addMoreDataQ1(value) {
      if (value === 2) {
        this.showForm = false;
      } else {
        this.showForm = true;
      }
    },

    /**
     * @function addMoreDataValueType - function that is called when this.addMoreDataValueType is changed
     * @param value
     */
    addMoreDataValueType(value) {
      this.previewNewRecordImage = null;

      if (value === "number") {
        this.displayValue = true;
        this.displayUnits = true;
        this.displayText = false;
        this.displayDateTime = false;
        this.addMoreDataValue = 0;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "text" || value === "textarea") {
        this.displayText = true;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "datetime") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = true;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "image") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = true;
        this.displayRadio = false;
        this.displayDropdown = false;
      } else if (value === "dropdown") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = true;
      } else if (value === "radio") {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = true;
        this.displayDropdown = false;
      } else {
        this.displayText = false;
        this.displayValue = false;
        this.displayUnits = false;
        this.displayDateTime = false;
        this.displayImage = false;
        this.displayRadio = false;
        this.displayDropdown = false;
      }
    },

    /**
     * @function images - function that is called when this.images is changed
     * @param val
     */
    images(val) {
      if (val.length > 0) {
        this.bigImageUrl = val[0].path;
      } else {
        this.bigImageUrl = "";
      }
    }
  },

  methods: {
    ...mapActions({
      getObservation: "observation/show/getObservation",
      deleteObservation: "observation/show/deleteObservation",
      resetError: "observation/show/resetError",
      setError: "observation/show/setError",
      uploadFile: "observation/show/uploadObservationImage",
      createRecord: "observation/show/createRecord",
      observationUpdate: "observation/show/observationUpdate",
      mainObservationDetailsUpdate:
        "observation/show/mainObservationDetailsUpdate",
      updateLocationInfo: "location/show/updateLocationInfo"
    }),

    /**
     * @function onEditTopDetails
     * @param detailsName
     * @param currItem
     * Function gets called when the main observation details will be in the editing mode
     */
    onEditTopDetails(detailsName, currItem) {
      if (detailsName === "date") {
        this.editField.isObservedDateEdit = true;
        let d = new Date(this.getDisplayDateTime(currItem.observedAt));
        this.editDateValue = new Date(
          this.getDisplayDateTime(currItem.observedAt)
        );
      } else if (detailsName === "location") {
        this.editField.isLocationEdit = true;
        this.locationModel.locationName = currItem.location.name;
        this.locationModel.longitude = currItem.location.longitude;
        this.locationModel.latitude = currItem.location.latitude;
      }
    },

    /**
     * @function onCancelMainDetailsEditing
     * @param detailsName
     * Function gets called when we cancel the main observation details edit mode
     */
    onCancelMainDetailsEditing(detailsName) {
      if (detailsName === "date") {
        this.editField.isObservedDateEdit = false;
        this.editDateValue = "";
      } else if (detailsName === "location") {
        this.editField.isLocationEdit = false;
        this.locationModel = {
          locationName: "",
          latitude: "",
          longitude: ""
        };
      }
    },

    /**
     * @function onSlideStart
     * @param slide
     * Event Gets fired when slide for the carousel starts
     */
    onSlideStart(slide) {
      this.sliding = true;
    },

    /**
     * @function onSlideEnd
     * @param slide
     * Event Gets fired when slide for the carousel ends
     */
    onSlideEnd(slide) {
      this.sliding = false;
    },


    /**
     * @function onSubmitData
     * @param currItem
     * @param modalVal
     * @param detailsName
     */
    onSubmitData(currItem, modalVal, detailsName) {
      let observationMainData;
      if (detailsName === "date") {
        this.item.observedAt = new Date(this.editDateValue);
      } else if (detailsName === "location") {
        this.item.location.name = this.locationModel.locationName;
        let validCoordinates = this.isValidCoordinates(
          this.locationModel.latitude,
          this.locationModel.longitude,
          this.item
        );

        if (!validCoordinates) {
          return;
        }
        const data = {
          id: this.item.location.id,
          locationInfo: this.item.location
        };
        this.updateLocationInfo(data).then(result => {
          debugger;
          this.item.location = result;
          this.onCancelMainDetailsEditing(detailsName);
        });
      }
      if (detailsName !== "location") {
        this.mainObservationDetailsUpdate({
          id: this.item.id,
          observation: this.item
        }).then(data => {
          if (data && typeof data == "object") {
            this.onCancelMainDetailsEditing(detailsName);
          }
        });
      }
    },
    /**
     * @function prepareForDisplayRecords
     * @param item
     * @returns {Array}
     */
    prepareForDisplayRecords(item) {
      if (item.recordType === "text" || item.recordType === "textarea") {
        return this.prepareItemText(item);
      }

      if (item.recordType === "dropdown") {
        return this.prepareItemDropdown(item);
      }

      if (item.recordType === "radio") {
        return this.prepareItemRadio(item);
      }

      if (item.recordType === "number") {
        return this.prepareItemNumber(item);
      }

      if (item.recordType === "datetime") {
        return this.prepareItemDateTime(item);
      }

      if (
        item.recordType === "image" &&
        item.fileObject &&
        item.fileObject.path
      ) {

        // Prevent image records from being shown more than once
        // - image sub-records appear more than once (e.g. under an organism)
        let img = this.images.filter(img => {
           if (img.path === item.fileObject.path) {
             return img
           }
         })
         if (img && img.length < 1) {
        this.images.push({
          path: item.fileObject.path,
          current: true
        });
        }
        
        return this.prepareItemImage(item);
      }

      return null;
    },

    /**
     * @function onAddModeDataClick - click handler for add more data button
     */
    onAddModeDataClick() {
      this.showAddModeData = !this.showAddModeData;
    },

    /**
     * @function onSubmitAddModeDataClick - click handler for submit button
     */
    onSubmitAddModeDataClick() {
      let type = "text";
      let value = "";
      let unit = "";
      let question_text = "";
      let record = null;

      let lastOrderNumber = 0;
      this.item.records.forEach((record, recordIndex) => {
        if (lastOrderNumber < record.orderNumber) {
          lastOrderNumber = record.orderNumber;
        }
      });

      if (this.addMoreDataValueType === "") {
        return;
      } else if (this.addMoreDataValueType === "number") {
        if (isNaN(this.addMoreDataValue)) {
          // not a number
          return;
        }

        this.addMoreDataValue = parseFloat(this.addMoreDataValue).toFixed(1);
        record = this.prepareAddNumberRecord(lastOrderNumber);
      } else if (
        this.addMoreDataValueType === "text" ||
        this.addMoreDataValueType === "textarea"
      ) {
        record = this.prepareAddTextRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "datetime") {
        record = this.prepareAddDateTimeRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "dropdown") {
        record = this.prepareAddDropdownRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "radio") {
        record = this.prepareAddDropdownRecord(lastOrderNumber);
      } else if (this.addMoreDataValueType === "image") {
        this.prepareAddImageRecord(lastOrderNumber, value);

        return;
      }

      this.createRecord(record).then(data => {
        this.addMoreDataQ1 = 1;
        this.addMoreDataMN = "";
        this.addMoreDataValueType = "";
        this.addMoreDataValue = "";
        this.addMoreDataUnits = "";
        this.textAreaValue = "";
        this.dateValue = "";
        this.dropDownList = [
          {
            fieldVal: ""
          }
        ];
        this.chooseList = [
          {
            fieldVal: ""
          }
        ];
      });
    },

    /**
     * @function prepareAddTextRecord
     * @param lastOrderNumber
     * @returns {{orderNumber: *, field: {dataType: string}, records: Array, recordType: string, observation: *, label: string, value: string}}
     */
    prepareAddTextRecord(lastOrderNumber) {
      return {
        label: this.addMoreDataMN,
        field: {
          dataType: "text"
        },
        orderNumber: lastOrderNumber + 1,
        recordType: this.addMoreDataValueType,
        records: [],
        value: this.textAreaValue,
        observation: this.item["@id"]
      };
    },

    /**
     * @function prepareAddNumberRecord
     * @param lastOrderNumber
     * @returns {{orderNumber: *, field: {dataType: string}, records: Array, recordType: string, observation: *, label: string, value: *}}
     */
    prepareAddNumberRecord(lastOrderNumber) {
      return {
        label:
          this.addMoreDataMN +
          (this.addMoreDataUnits.length
            ? " (" + this.addMoreDataUnits + ")"
            : ""),
        field: {
          dataType: "number"
        },
        orderNumber: lastOrderNumber + 1,
        recordType: "number",
        records: [],
        value: this.addMoreDataValue,
        observation: this.item["@id"]
      };
    },

    /**
     * @function prepareAddDateTimeRecord
     * @param lastOrderNumber
     * @returns {{orderNumber: *, field: {dataType: string}, records: Array, recordType: string, observation: *, label: string, value: Date}}
     */
    prepareAddDateTimeRecord(lastOrderNumber) {
      let dateObj = this.dateForServer(this.dateValue);

      return {
        label: this.addMoreDataMN,
        field: {
          dataType: "datetime"
        },
        orderNumber: lastOrderNumber + 1,
        recordType: "datetime",
        records: [],
        value: dateObj,
        observation: this.item["@id"]
      };
    },

    /**
     * @function prepareAddImageRecord
     * @param lastOrderNumber
     */
    prepareAddImageRecord(lastOrderNumber) {
      this.uploadFile({ fd: this.recordImageUpload }).then(data => {
        this.createRecord({
          label: this.addMoreDataMN,
          field: {
            dataType: "file"
          },
          orderNumber: lastOrderNumber + 1,
          recordType: "image",
          records: [],
          fileObject: data["@id"],
          value: null,
          observation: this.item["@id"]
        }).then(data => {
          this.addMoreDataQ1 = 1;
          this.addMoreDataMN = "";
          this.addMoreDataValueType = "";
          this.addMoreDataValue = "";
          this.addMoreDataUnits = "";
          this.textAreaValue = "";
          this.dateValue = "";
        });
      });
    },

    /**
     * @function prepareAddDropdownRecord
     * @param lastOrderNumber
     * @returns {*}
     */
    prepareAddDropdownRecord(lastOrderNumber) {
      let options = [];
      let recordType = "";

      if (this.displayDropdown) {
        this.dropDownList.forEach((item, index) => {
          options.push({
            dataType: "text",
            value: item.fieldVal,
            orderNumber: index
          });
        });

        recordType = "dropdown";
      } else if (this.displayRadio) {
        this.chooseList.forEach((item, index) => {
          options.push({
            dataType: "text",
            value: item.fieldVal,
            orderNumber: index
          });
        });

        recordType = "radio";
      } else {
        return null;
      }

      return {
        label: this.addMoreDataMN,
        field: {
          dataType: "set",
          optionsSet: {
            optionsValues: options
          }
        },
        orderNumber: lastOrderNumber + 1,
        recordType: recordType,
        records: [],
        observation: this.item["@id"]
      };
    },

    /**
     *
     * @returns {void|*}
     */
    onFileUploadNewRecord(file) {
      const _files = file.target.files || file.dataTransfer.files;
      const fileTypesAllowed = ["jpg", "jpeg", "png"];
      let object = {};

      if (!_files.length) return;

      const filename = _files[0].name;

      if (
        filename.lastIndexOf(".") <= 0 ||
        fileTypesAllowed.indexOf(
          filename
            .split(".")
            .pop()
            .toLowerCase()
        ) === -1
      ) {
        return this.setError(
          "Please select a valid file type: jpg, jpeg, png"
        );
      }

      this.recordImageUpload = new FormData();
      this.recordImageUpload.append("file", _files[0], _files[0].name);
      this.recordImageUpload.append("actionType", "observation_image");

      const fileReader = new FileReader();

      fileReader.onload = e => {
        this.previewNewRecordImage = e.target.result;
      };

      fileReader.readAsDataURL(_files[0]);
    },

    /**
     * @function addNewFiled
     * @param evt
     */
    addNewFiled(evt) {
      evt.stopImmediatePropagation();
      evt.preventDefault();

      if (this.displayRadio) {
        this.chooseList.push({ fieldVal: "" });
      } else if (this.displayDropdown) {
        this.dropDownList.push({ fieldVal: "" });
      }
    },

    /**
     * @function removeField
     * @param evt
     * @param index
     */
    removeField(evt, index) {
      evt.stopImmediatePropagation();
      evt.preventDefault();

      if (this.displayRadio) {
        this.chooseList.splice(index, 1);
      } else if (this.displayDropdown) {
        this.dropDownList.splice(index, 1);
      }
    },

    /**
     * @function onImageClick - click handler for small image click
     * @param imageUrl
     */
    onImageClick(imageUrl, index) {
      this.bigImageUrl = imageUrl;

      this.images.forEach((val, idx) => {
        this.images[idx].current = false;
      });

      this.images[index].current = true;
    },

    /**
     * @function onFileUploadChange - change handler for the file input type
     * @param {object} file - event object
     */
    onFileUploadChange(file) {
      const _files = file.target.files || file.dataTransfer.files;
      const fileTypesAllowed = ["jpg", "jpeg", "png"];
      let object = {};

      if (!_files.length) return;

      const filename = _files[0].name;

      if (
        filename.lastIndexOf(".") <= 0 ||
        fileTypesAllowed.indexOf(
          filename
            .split(".")
            .pop()
            .toLowerCase()
        ) === -1
      ) {
        return this.setError(
          "Please select a valid file type: jpg, jpeg, png"
        );
      }

      let fd = new FormData();
      fd.append("file", _files[0], _files[0].name);
      fd.append("actionType", "observation_image");

      this.uploadFile({ fd: fd }).then(data => {
        let observation = { files: [] };

        this.item.files.forEach((item, index) => {
          observation.files.push(item["@id"]);
        });

        observation.files.push({
          file: data["@id"]
        });
        this.observationUpdate({ id: this.item.id, observation: observation });
      });
    },

    /**
     * @function prepareItemText
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: *, selected: boolean}[], type: *}}
     */
    prepareItemText(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value: item.value,
            selected: true
          }
        ]
      };
    },

    /**
     * @function prepareItemDropdown
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: Array, type: *}}
     */
    prepareItemDropdown(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.optionValue ? item.optionValue.id : "",
            value: item.optionValue ? item.optionValue.value : item.value,
            selected: true
          }
        ]
      };
    },

    /**
     * @function prepareItemRadio
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: Array, type: *}}
     */
    prepareItemRadio(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.optionValue.id,
            value: item.optionValue.value,
            selected: true
          }
        ]
      };
    },

    /**
     * @function prepareItemNumber
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: *, selected: boolean}[], type: *}}
     */
    prepareItemNumber(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value: item.value,
            selected: false
          }
        ]
      };
    },

    /**
     * @function prepareItemDateTime
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: *, selected: boolean}[], type: *}}
     */
    prepareItemDateTime(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value: item.value,
            selected: false
          }
        ]
      };
    },

    /**
     * @function prepareItemImage
     * @param item
     * @returns {{question_text: *, questionId: *, edit: boolean, answers: {id: *, value: Array, selected: boolean}[], type: *}}
     */
    prepareItemImage(item) {
      return {
        questionId: item.id,
        question_text: item.label,
        type: item.recordType,
        edit: false,
        answers: [
          {
            id: item.id,
            value:
              item.fileObject && item.fileObject.path
                ? item.fileObject.path
                : "",
            selected: true
          }
        ]
      };
    },

    /**
     * @function onDeleteObservationClick - when the user click on delete observation button
     * @param event
     */
    onDeleteObservationClick(event) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.resetError();

      this.$refs.confirmationModal.show();
    },

    /**
     * @function clickAddDataLink - function that handle the Add Data button press. Check if user is logged in.
     *    If the user is not logged in display a popup with a message if it is redirecting the user to the datasheet
     */
    clickAddDataLink() {
      let datasheetId = this.item.datasheet.replace("/datasheets/", "");

      if (JSON.parse(localStorage.getItem("sessionData")) == null) {
        this.$root.$emit("bv::show::modal", "not-logged-in-modal");
        return;
      } else {
        this.$router.push({
          name: "DatasheetView",
          params: { id: datasheetId }
        });
      }
    },

    /**
     * @function onYesClick - when the user click on Yes button on delete confirmation
     */
    onYesClick() {
      this.deleteObservation({ id: this.item.id }).then(data => {
        if (typeof data === "boolean" && data === false) {
          this.$refs.confirmationModal.hide();
        } else {
          this.$router.push({
            name: "ProjectShow",
            params: { id: this.item.project.urlField }
          });
        }
      });
    },

    /**
     * @function onNoClick - when the user click on No button on delete confirmation
     */
    onNoClick() {
      this.$refs.confirmationModal.hide();
    },

    /**
     * @function onDismissedClick - when the user dismiss error alert, we reset error message
     */
    onDismissedClick() {
      this.resetError();
    },

    /**
     * @function onCancelImageUpload
     * @param evt
     */
    onCancelImageUpload(evt) {
      if (this.previewNewRecordImage != null) {
        this.previewNewRecordImage = null;
      } else if (this.previewEditRecordImage != null) {
        this.previewEditRecordImage = null;
      }
    },

    /**
     *
     */
    onEditActiveChange() {
      this.editActive = !this.editActive;
    },

    /**
     * @function getOrganismName
     * @param organismRecordSelectedSpecies
     * @returns {string}
     */
    getOrganismName(organismRecordSelectedSpecies) {
      return organismRecordSelectedSpecies
        ? organismRecordSelectedSpecies.customName
          ? organismRecordSelectedSpecies.customName
          : organismRecordSelectedSpecies.canonicalName &&
            organismRecordSelectedSpecies.scientificName
          ? organismRecordSelectedSpecies.canonicalName +
            " (" +
            organismRecordSelectedSpecies.scientificName +
            ")"
          : ""
        : "";
    },

    /**
     * @function locationDetailsShow
     * @param routeName
     * @param routeParam
     */
    locationDetailsShow(routeName, routeParam) {
      this.$router.push({ name: routeName, params: { id: routeParam } });
    },

    /**
     * @function getDisplayDateTime - For now, we've decided to not deal with
     *  timezone so this function will remove the timezone portion of the date
     *  (+), if it exists in the value to avoid incorrect timezone conversion
     * @param {string/object} value - the date value to use, can be either
     *  String or Object (will assume to be a Date object)
     * @param {String} the date&time using moment (without timezone)
     */
    getDisplayDateTime(value) {
      if (value) {
        let index = -1;
        let type = typeof value;

        // Tried using .equals() and .compareTo() function but got runtime
        // error so have to use ==
        if (type == "string") index = value.search(/\+/);
        else if (type == "object") value = value.toISOString();

        if (index == -1) {
          return moment(value).format("ddd MMMM DD, YYYY h:mm:ss a");
        } else {
          let new_date = value.substring(0, index);
          return moment(new_date).format("ddd MMMM DD, YYYY h:mm:ss a");
        }
      }
      return "";
    }
  },

  created() {
    this.$root.$on("editActiveChange", this.onEditActiveChange);
    this.resetError();
    this.getObservation({ id: this.$route.params.id }).then(data => {
      if (data) {
        this.locationData = data.location;
        // this.locationData.latitude = Math.round(locationData.latitude * 10000) / 10000
        // this.locationData.longitude = Math.round(locationData.longitude * 10000) / 10000

        const commonTweetText =
          " observation via @Citsci #PoweredbyCitSci #CitizenScience #citsci " +
          this.currentUrl;
        this.twitterProjectName = !data.project.twitterLink
          ? "Check out this" + commonTweetText
          : data.project.twitterLink.indexOf("@") === 0
          ? data.project.twitterLink
          : "@" + data.project.twitterLink;
        if (data.project.twitterLink) {
          this.twitterProjectName =
            "Check out this " + this.twitterProjectName + commonTweetText;
        }
      } else {
        this.$router.push({ name: "NotFound" });
      }
      /*
      TODO: Once finalized, what to do put for facebook uncomment this and put this
      this.facebookProjectName = data.project.facebookLink;*/
    });

    /*let shortUrl = this.bitlyURL(
      "https://" + "citsci.org" + "/observations/show/" + this.$route.params.id
    );

    if (!shortUrl) {
      this.currentUrl = window.location.href;
      this.isLoadingShortUrl = false;
    } else {
      shortUrl.then(responseJson => {
        this.currentUrl =
          responseJson.status_code === 200
            ? responseJson.data.url
            : window.location.href;
        this.isLoadingShortUrl = false;
      });
    }*/
  }
};
