<template>
  <b-modal id="learn-more-modal"
           size="xl"
           ref="learnMoreModal"
           centered
           ok-only
           ok-title="Close">

    <template slot="modal-header" class="mx-auto">
      <h2 v-if="project" class="p-0 mt-0 mb-0">Welcome to {{ project.name }}</h2>
    </template>

    <div v-if="project">
      <h4>About</h4>
      <p class="projectDetailsText">{{ project.description }}</p>

      <h4>Goals</h4>
      <p class="projectDetailsText">{{ project.goals }}</p>

      <h4>Tasks</h4>
      <p class="projectDetailsText">{{ project.tasks }}</p>

      <h4>Get Started</h4>
      <p class="projectDetailsText">{{ project.gettingStarted }}</p>

      <div class="row mt-3" v-if="project.website">
        <div class="col-lg-2">
          <h4>Website:</h4>
        </div>
        <div class="col-lg-10">
          <a :href="project.website" target="NewWindow"
          >{{ project.website }} <i class="fas fa-external-link-alt"></i
          ></a>
        </div>
      </div>

      <div
        class="row mt-3" v-if="project.facebookLink || project.twitterLink">
        <div class="col-lg-2 mt-1">
          <h4>Follow Us</h4>
        </div>
        <div class="col-lg-10 mt-1">
          <a
            v-if="project.twitterLink"
            :href="project.twitterLink"
            target="NewWindow"
          ><i class="fab fa-3x fa-twitter-square"></i
          ></a>

          <a v-if="project.facebookLink" :href="project.facebookLink" target="NewWindow">
            <i class="fab fa-3x fa-facebook-square"></i>
          </a>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {},
  components: {},
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapGetters({
      project: 'project/get/project',
      projectId: 'project/get/id',
      isLoading: 'project/get/isLoading',
    }),
  },
  methods: {},
}
</script>

<style lang="scss" scoped>

.projectDetailsText {
  padding-right: 6px !important;
  font-size: 1.1rem !important;
  color: grey !important;
  text-align: left !important;
}

</style>
