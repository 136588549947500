<template>
  <b-form v-if="isManager" @submit.prevent="saveChanges" novalidate>
    <VueLoader v-if="isLoading" />
    <div class="card-body border-bottom">
      <b-row>
        <b-col col md="4" class="mb-2 ">
          <h2 class="m-0">Manage Hub</h2>
        </b-col>
        <b-col col md="8" class="text-right mb-2">
          <div v-if="organizationVuex" class="mr-3">
            <span>
              Last Saved:
            </span>
            <FromNow
              :dateTime="organizationVuex.updatedAt"
              class="font-weight-bold"
            >
            </FromNow>
            <i
              id="timestamps-updated"
              class="fa fa-question-circle text-secondary"
            ></i>
          </div>
          <b-button
            v-if="!isLoading"
            class="btn-primary mr-2"
            type="submit"
            variant="primary"
            alt="Save Changes to Your Citizen Science Hub Now"
            title="Save Changes to Your Citizen Science Hub Now"
          >
            Save Changes
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div class="card-body pb-0" v-if="organizationLocal">
      <b-row>
        <b-col lg="7" md="12" class="mt-0">
          <OrganizationBannerImage></OrganizationBannerImage>
          <div class="logo-overlay">
            <OrganizationLogoImage></OrganizationLogoImage>
          </div>
          <b-form-group>
            <b-form-group
              id="organization-name-input-group"
              class="mt-4"
              label="Hub Name"
              label-for="organization-name-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <h3>
                {{ organizationVuex && organizationVuex.name }}
              </h3>
            </b-form-group>

            <b-form-group
              id="organization-url-input-group"
              class="mt-4"
              label="Hub URL"
              label-for="organization-url-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-input-group-prepend>
                <font-awesome-icon
                  icon="question-circle"
                  id="organizationUrl-info"
                  class="organization-url-icon"
                />
              </b-input-group-prepend>
              <h6>
                {{ organizationVuex && urlField }}&nbsp;&nbsp;&nbsp;&nbsp;
                <b-btn size="sm" @click="copyURLToClipboard">
                  <i class="fa fa-clipboard fa-lg"></i>
                  &nbsp;Copy
                </b-btn>
              </h6>
            </b-form-group>

            <b-form-group
              id="organization-description-input-group"
              label="Description"
              label-for="organization-description-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-form-textarea
                id="organization-description-textarea"
                name="description"
                placeholder="Please provide a description of your organization (Minimum of 10 characters)"
                v-model="organizationLocal && organizationLocal.description"
                v-validate="'required|min:10|max:4000'"
                data-vv-as="Description"
                :class="{ 'is-invalid': errors.has('description') }"
                :rows="3"
                :max-rows="6"
                aria-describedby="organization-description-input-live-feedback"
              >
              </b-form-textarea>
              <b-form-invalid-feedback
                id="organization-description-input-live-feedback"
              >
                {{ errors.first("description") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="organization-shortDescription-input-group"
              label="Short Description"
              label-for="organization-shortDescription-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-form-textarea
                id="organization-shortDescription-textarea"
                name="shortDescription"
                placeholder="Please enter a short text describing what your organization hopes to accomplish"
                v-model="
                  organizationLocal && organizationLocal.shortDescription
                "
                v-validate="'required|min:10|max:200'"
                data-vv-as="Short Description"
                :class="{ 'is-invalid': errors.has('shortDescription') }"
                :rows="3"
                :max-rows="6"
                aria-describedby="organization-shortDescription-input-live-feedback"
              >
              </b-form-textarea>
              <b-form-invalid-feedback
                id="organization-shortDescription-input-live-feedback"
              >
                {{ errors.first("shortDescription") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="organization-website-input-group"
              class="mt-4"
              label="Website URL"
              label-for="organization-website-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-input-group-prepend>
                <font-awesome-icon
                  icon="question-circle"
                  id="website-info"
                  class="website-url-icon"
                />
              </b-input-group-prepend>
              <b-form-input
                id="organization-website-input"
                type="text"
                name="website"
                placeholder="Website URL (e.g. https://www.citsci.org)"
                v-model.trim="organizationLocal && organizationLocal.website"
                v-validate="'min:10|max:100'"
                data-vv-as="Website"
                :class="{ 'is-invalid': errors.has('website') }"
                aria-describedby="organization-website-input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback
                id="organization-website-input-live-feedback"
              >
                {{ errors.first("website") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="organization-facebookLink-input-group"
              class="mt-4"
              label="Facebook Page"
              label-for="facebook-name-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-form-input
                id="organization-facebookLink-input"
                type="text"
                maxlength="100"
                name="facebookLink"
                placeholder="Facebook URL (e.g. https://www.facebook.com/CitSci.org/)"
                v-model.trim="
                  organizationLocal && organizationLocal.facebookLink
                "
                v-validate="'min:10|max:100'"
                data-vv-as="Facebook"
                :class="{ 'is-invalid': errors.has('facebookLink') }"
                aria-describedby="organization-facebookLink-input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback
                id="organization-facebookLink-input-live-feedback"
              >
                {{ errors.first("facebookLink") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="organization-twtLink-input-group"
              class="mt-4"
              label="Twitter URL"
              label-for="organization-twtLink-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-form-input
                id="organization-twtLink-input"
                type="text"
                maxlength="100"
                name="twtLink"
                placeholder="Twitter URL (e.g. https://twitter.com/citsci)"
                v-model.trim="
                  organizationLocal && organizationLocal.twitterLink
                "
                v-validate="'min:10|max:100'"
                data-vv-as="Twitter"
                :class="{ 'is-invalid': errors.has('twtLink') }"
                aria-describedby="organization-twtLink-input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback
                id="organization-twtLink-input-live-feedback"
              >
                {{ errors.first("twtLink") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="organization-recentNews-input-group"
              class="mt-4"
              label="Recent News"
              label-for="organization-recentNews-input"
              label-class="font-weight-bold"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-form-textarea
                id="organization-recentNews-textarea"
                name="recentNews"
                placeholder="Please enter any recent news to share on the Hub Page..."
                v-model="organizationLocal && organizationLocal.recentNews"
                v-validate="'min:15|max:1000'"
                data-vv-as="Recent News"
                :class="{ 'is-invalid': errors.has('recentNews') }"
                :rows="3"
                :max-rows="6"
                aria-describedby="organization-recentNews-input-live-feedback"
              >
              </b-form-textarea>
              <b-form-invalid-feedback
                id="organization-recentNews-input-live-feedback"
              >
                {{ errors.first("recentNews") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form-group>
        </b-col>

        <b-col lg="5" md="12" class="mt-0">
          <b-form-group>
            <b-card class="special">
              <b-card-body>
                <b-container class="mb-0">
                  <h4>
                    Settings
                    <span class="h4-icon"><i class="fa fa-wrench"></i></span>
                  </h4>
                  <b-row>
                    <b-col md="7" sm="12" class="mt-2">
                      <b>Project Affiliation</b>
                      <font-awesome-icon
                        icon="question-circle"
                        id="affiliation-info"
                      />
                    </b-col>
                    <b-col md="5" sm="12" class="mt-2">
                      <toggle-button
                        id="affiliation-btn"
                        color="#00c8f8"
                        v-model="
                          organizationLocal &&
                            organizationLocal.approveAffiliation
                        "
                        :sync="true"
                        :labels="{
                          checked: 'Approval Required',
                          unchecked: 'Open'
                        }"
                        :width="125"
                      />
                    </b-col>
                  </b-row>
                </b-container>
                <OrganizationAffiliations></OrganizationAffiliations>
              </b-card-body>
            </b-card>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="organization">
      <div class="card-header border-top">
        <h3 class="m-0">
          Headquarters Location
          <font-awesome-icon
            class="fa-sm"
            icon="question-circle"
            id="organization-location-info"
          />
        </h3>
      </div>
      <vue-google-map
        :item="organizationLocal"
        :latitude="organizationLocal && organizationLocal.latitude"
        :longitude="organizationLocal && organizationLocal.longitude"
        :is-loading="false"
        :custom-marker="false"
        :zoom-map-to-markers="11"
        :show-locate-address="true"
        :zoom-val="5"
        :show-address-flag="true"
        class="organization-create-edit"
      />
    </div>
    <div class="card-footer">
      <b-row class="">
        <b-col col md="12" class="text-right">
          <div v-if="organizationVuex" class="mr-3">
            <span>
              Last Saved:
            </span>
            <FromNow
              :dateTime="organizationVuex.updatedAt"
              class="font-weight-bold"
            >
            </FromNow>
            <i
              id="timestamps-updated-bottom"
              class="fa fa-question-circle text-secondary"
            ></i>
          </div>
          <b-button
            v-if="!isLoading"
            class="btn-primary mr-2"
            type="submit"
            variant="primary"
            alt="Save Changes to Your Citizen Science Hub Now"
            title="Save Changes to Your Citizen Science Hub Now"
          >
            Save Changes
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-tooltip target="affiliation-info" placement="bottom">
      Choose whether Hub affiliation is Open (any project can affiliate
      without your approval) or Approve (requests to affiliate require your
      approval)
    </b-tooltip>
    <b-tooltip target="organizationUrl-info" placement="right">
      This will be the web address you can use to have volunteers access your
      citizen science organization directly. Simply have your volunteers copy
      and paste this into their web browser to easily access your organization
    </b-tooltip>
    <b-tooltip target="website-info" placement="right">
      External website URL
    </b-tooltip>
    <b-tooltip target="organization-location-info" placement="bottom">
      Manually enter coordinates, click on the map, or search for an address to
      obtain coordinates. This represents your organization headquarters. Please
      use decimal degrees (not degrees, minutes, seconds).
    </b-tooltip>
    <b-tooltip
      v-if="organizationVuex"
      target="timestamps-updated"
      variant="light"
      placement="left"
      custom-class="timestamp-tooltip-class"
    >
      <div>
        <div>
          <strong>Updated</strong>: {{ moment(organizationVuex.updatedAt) }}
        </div>
        <hr />
        <div>
          <strong>Created</strong>: {{ moment(organizationVuex.createdAt) }}
        </div>
      </div>
    </b-tooltip>
    <b-tooltip
      v-if="organizationVuex"
      target="timestamps-updated-bottom"
      variant="light"
      placement="left"
      custom-class="timestamp-tooltip-class"
    >
      <div>
        <div>
          <strong>Updated</strong>: {{ moment(organizationVuex.updatedAt) }}
        </div>
        <hr />
        <div>
          <strong>Created</strong>: {{ moment(organizationVuex.createdAt) }}
        </div>
      </div>
    </b-tooltip>
  </b-form>
  <div v-else>
    <div v-if="!isLoading" class="alert-danger alert">
      Editing a Hub requires Admin Access (Click on any other Tab)
    </div>
  </div>
</template>

<script>
import VueGoogleMap from "../../../../assets/scripts/layout/GoogleMap";
import VueLoader from "../../../layout/Loader";
import { mapGetters, mapActions } from "vuex";
import OrganizationBannerImage from "./OrganizationBannerImage";
import OrganizationLogoImage from "./OrganizationLogoImage";
import OrganizationAffiliations from "./OrganizationAffiliations.vue";
import FromNow from "../../../layout/FromNow";
import { appRootUrl } from "../../../../main";

export default {
  components: {
    VueGoogleMap,
    VueLoader,
    OrganizationBannerImage,
    OrganizationLogoImage,
    FromNow,
    OrganizationAffiliations
  },
  data() {
    return {
      organizationLocal: null,
      hasChanges: false,
      originalCopy: null
    };
  },
  created() {},
  watch: {
    organizationLocal: {
      deep: true,
      // Enable save button when any change is detected
      handler: function(newVal, oldVal) {
        // console.log('change made', newVal, oldVal)
        if (oldVal === null) {
          // Initial load, attributes are extracted
          this.hasChanges = false;
          let fetch = this.organization;
        } else {
          // console.log('newVal', newVal)
          // console.log('oldVal', this.originalCopy)
          this.hasChanges = !(
            JSON.stringify(newVal) === JSON.stringify(this.originalCopy)
          );
          // console.log('hasChanges', this.hasChanges)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      organizationVuex: "organization/get/organization",
      organizationId: "organization/get/id",
      isLoading: "organization/get/isLoading",
      membershipMap: "user/profile/organizationMembershipMap"
    }),
    isManager() {
      return (
        this.organizationVuex &&
        this.membershipMap[this.organizationId] &&
        this.membershipMap[this.organizationId].isManager
      );
    },
    organization() {
      // Set this up only the first time
      if (this.organizationVuex && this.originalCopy === null) {
        let arrAttr = [
          "description",
          "shortDescription",
          "latitude",
          "longitude",
          "website",
          "facebookLink",
          "twitterLink",
          "approveAffiliation",
          "recentNews"
        ];
        this.organizationLocal = Object.fromEntries(
          Object.entries({ ...this.organizationVuex }).filter(([key]) =>
            arrAttr.includes(key)
          )
        );
        // A deep copy of the original state
        if (this.originalCopy === null) {
          this.originalCopy = { ...this.organizationLocal };
        }
      }
      return this.organizationLocal;
    },
    /**
     * @function updateUrlField
     * @returns {string}
     */
    urlField() {
      if (this.organizationVuex) {
        return appRootUrl + "/organizations/" + this.organizationVuex.url;
      }
    }
  },
  methods: {
    ...mapActions({
      updateOrganization: "organization/get/updateOrganization"
    }),
    async copyURLToClipboard() {
      try {
        await navigator.clipboard.writeText(this.urlField);
        this.$bvToast.toast("Paste Anywhere", {
          title: "Hub URL Copied",
          autoHideDelay: 2500,
          variant: "success"
        });
      } catch ($e) {
        // console.log('copy to clipboard failed.')
      }
    },
    moment(value) {
      return moment(value);
    },
    saveChanges() {
      // Once validation is passed, organization changes are saved.
      this.$validator.validateAll().then(result => {
        if (result) {
          this.updateOrganization(this.organizationLocal).then(response => {
            this.hasChanges = false;
            this.originalCopy = null;
            this.organizationLocal = this.organization;
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
input::placeholder,
textarea::placeholder,
#organization-longitude-input::placeholder {
  color: #0b7fc7;
  opacity: 0.54;
}
.logo-overlay {
  position: absolute;
  width: 180px;
  height: 160px;
  top: 20px;
  bottom: 0;
  left: auto;
  right: 40px;
}
.card-header {
  background-color: #f5f4e8 !important;
}
.disabled {
  cursor: not-allowed;
}
h1,
h2,
h3,
h4 {
  vertical-align: middle;
}
h1 > .h4-icon,
h2 > .h4-icon,
h3 > .h4-icon,
h4 > .h4-icon {
  vertical-align: middle;
  margin-top: -12px; /* -0.5em */
  font-size: 12px;
  border: solid 0px red;
}
.organization-url-icon {
  @media screen and (max-width: 576px) {
    position: absolute;
    top: -25px;
    left: 85px;
  }
  @media (min-width: 577px) and (max-width: 667px) {
    position: absolute;
    top: 8px;
    left: -65px;
  }
  @media (min-width: 668px) and (max-width: 767px) {
    position: absolute;
    top: 8px;
    left: -67px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    position: absolute;
    top: 8px;
    left: -120px;
  }
  @media (min-width: 992px) and (max-width: 1115px) {
    position: absolute;
    top: 8px;
    left: -28px;
  }
  position: absolute;
  top: 8px;
  left: -38px;
}
.website-url-icon {
  @media screen and (max-width: 576px) {
    position: absolute;
    top: -25px;
    left: 95px;
  }
  @media (min-width: 577px) and (max-width: 667px) {
    position: absolute;
    top: 8px;
    left: -55px;
  }
  @media (min-width: 668px) and (max-width: 767px) {
    position: absolute;
    top: 8px;
    left: -60px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    position: absolute;
    top: 8px;
    left: -110px;
  }
  @media (min-width: 992px) and (max-width: 1115px) {
    position: absolute;
    top: 8px;
    left: -18px;
  }
  position: absolute;
  top: 8px;
  left: -30px;
}
.timestamp-tooltip-class {
  margin-top: 15px;
  margin-right: 165px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
}
hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
