<template>
  <div v-if="isLoading">
    <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading datasheet questions...
  </div>

  <div v-else class="">
    <h5>Datasheet Questions ({{ datasheetRecords.length }})</h5>

    <div v-show="datasheetRecords.length <= 0">
      <p class="p-down">
        There are no datasheet questions for this datasheet. Please select a different datasheet.
      </p>
    </div>

    <div v-for="datasheetRecord in datasheetRecords" :key="datasheetRecord.id">
      <DatasheetRecordResults :key="datasheetId"
        :datasheet-record-id="datasheetRecord.id"
        :datasheet-record-label="datasheetRecord.label"
        :datasheet-record-type="datasheetRecord.recordType"
      >
      </DatasheetRecordResults>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DatasheetRecordResults from "./datasheetRecordResults.vue";

export default {
  components: {
    DatasheetRecordResults,
  },
  props: {
    datasheetId: {
      type: String,
      default: () => {
        return null
      },
      required: true,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    datasheetId(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getDatasheetRecords(newVal);
      }
    }
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      datasheetRecords: "project/results/datasheetRecords",
      isLoading: "project/results/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      getDatasheetRecords: "project/results/getDatasheetRecords",
    })
  },
  created() {
    this.getDatasheetRecords(this.datasheetId);
  },
}
</script>
