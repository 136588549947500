<template>
  <!-- 3 most recent Photo Observations -->
  <div class="pl-3" v-if="!isLoadingPhotoObs">
    <!-- v-if="photoObservations && photoObservations.length > 0" -->
    <div class="row mb-2">
      <h3>Recent Photo Observations
        <b-btn v-if="photoObservations"
             class="ml-2"
             variant="secondary"
             size="sm"
             alt="Reload Photo Observations" title="Reload Photo Observations"
             @click="getRecentPhotoObservations(4)">
          <font-awesome-icon icon="sync"/>
        </b-btn>
      </h3>
    </div>
    <div v-if="isLoadingPhotoObs" style="min-height:250px;">
      <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading recent photo observations...
    </div>
    <div v-else>
      <div v-if="photoObservations && photoObservations.length > 0">
        <b-row class="section observation">
          <PhotoObservationCard
            v-for="(observation, index) in photoObservations"
            :key="index"
            :observation="observation"
            :isHomePage=false>
          </PhotoObservationCard>
        </b-row>
      </div>
      <div v-else class="ml-4">
        <h6>No Recent Observations with Photos...</h6>
      </div>
    </div>
  </div>
  <div v-else class="pl-3 mb-4">
    <div class="row mb-2">
      <h3>Loading Recent Observations...<i class="fa fa-spin fa-spinner ml-3"></i></h3>
    </div>
    <div>
      <b-row>
        <b-card-group deck>
          <b-card bg-variant="light" header class="text-left mockCard mr-1 pr-0">
            <template #header>
              <div class="mockImageTop"></div>
            </template>
            <b-card-text p-0>
              <div class="mockOberverNameStripe">
                <span class="badge badge-pill badge-light w-50">&nbsp;&nbsp;</span>
              </div>
              <img src="/assets/images/default_avatar.png" class="rounded-circle float-right border-white mockAvatar">
              <span class="badge badge-pill w-50 mockData mt-3">&nbsp;&nbsp;</span>
              <span class="badge badge-pill w-50 mockData mt-1">&nbsp;&nbsp;</span>
              <span class="badge badge-pill w-50 mockData mt-1">&nbsp;&nbsp;</span>
            </b-card-text>
          </b-card>

          <b-card bg-variant="light" header class="text-center mockCard mr-1 ml-1 pr-0">
            <template #header>
              <div class="mockImageTop"></div>
            </template>
            <b-card-text p-0>
              <div class="mockOberverNameStripe">
                <span class="badge badge-pill badge-light w-50">&nbsp;&nbsp;</span>
              </div>
              <img src="/assets/images/default_avatar.png" class="rounded-circle float-right border-white mockAvatar">
            </b-card-text>
          </b-card>

          <b-card bg-variant="light" header class="text-center mockCard mr-1 ml-1 pr-0">
            <template #header>
              <div class="mockImageTop"></div>
            </template>
            <b-card-text p-0>
              <div class="mockOberverNameStripe">
                <span class="badge badge-pill badge-light w-50">&nbsp;&nbsp;</span>
              </div>
              <img src="/assets/images/default_avatar.png" class="rounded-circle float-right border-white mockAvatar">
            </b-card-text>
          </b-card>

          <b-card bg-variant="light" header class="text-center mockCard ml-1 mr-5">
            <template #header>
              <div class="mockImageTop"></div>
            </template>
            <b-card-text p-0>
              <div class="mockOberverNameStripe">
                <span class="badge badge-pill badge-light w-50">&nbsp;&nbsp;</span>
              </div>
              <img src="/assets/images/default_avatar.png" class="rounded-circle float-right border-white mockAvatar">
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PhotoObservationCard from "../../../layout/PhotoObservationCard";

export default {
  components: {
    PhotoObservationCard
  },
  // props: {
  //   isHomePage: true,
  // },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      photoObservations: "project/photo_observations/photoObservations",
      photoProjectId: "project/photo_observations/projectId",
      isLoadingPhotoObs: "project/photo_observations/isLoading",
    })
  },
  methods: {
    ...mapActions({
      getRecentPhotoObservations: "project/photo_observations/get",
    }),
  },
  created() {
    if (this.projectId !== this.photoProjectId)
      this.getRecentPhotoObservations(4)
  }
};
</script>

<style lang="scss" scoped>

.mockCard {
  min-height:272px;
  height:272px;
  max-height:272px;
  max-width:236px;
  min-width:236px;
  background-color: #F7F7F7;
}

.mockImageTop {
  min-height:127px;
  background-color: #F0F1F2;
}

.mockOberverNameStripe {
  margin-left:-18px;
  margin-top:-18px !important;
  min-height: 27px !important;
  background-color:#D9D9D9;
  min-width:235px !important;
  text-align:left;
  padding-left:4px;
}

.mockObserverName {
  max-height: 16px;
  min-height: 16px;
  height: 16px;
}

.mockAvatar {
  margin-top:-40px;
  margin-right:-10px;
  border: solid 1px white !important;
  width: 55px;
  height: 55px;
}

.mockData {
  background-color: #D9D9D9;
}

</style>
