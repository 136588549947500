import { mapActions, mapGetters } from 'vuex'
import { BTooltip } from 'bootstrap-vue';
import VueLoader from '../../../components/layout/Loader'
import ProjectJoinButton from '../project/projectJoinButton.vue'
import PhotoObservationCard from "../../../components/layout/PhotoObservationCard"
import PhotoProjectCard from "../../../components/layout/PhotoProjectCard";

export default {

  components: {
    BTooltip,
    VueLoader,
    ProjectJoinButton,
    PhotoObservationCard,
    PhotoProjectCard,
  },

  provide() {
    return { parentValidator: this.$validator }
  },

  data() {
    return {
      headerData: {
        backgroundPath: '/assets/images/header/Home_Image_1.jpg',
        picturePath: '',
        headerTitle: 'Your free participatory science platform',
        subHeaderTitle: 'Create projects, build datasheets, add data and view results in real-time',
        showHomepageButtons: true,
      },

      observationCardArr: [],

      testimonialsCards: [
        {
          id: 1,
          author: {
            firstName: 'Kate',
            lastName: 'Rinder',
            photo: '/assets/images/testimonials/KateRinder.png'
          },
          location: 'Youth Leadership Coordinator - North Cascades Institute',
          text: "I've looked at a number of different platforms and none of them have had the right tools and capacity for what we want to do. I'm excited that CitSci does!"
        },
        {
          id: 2,
          author: {
            firstName: 'Jorge',
            lastName: 'Figueroa',
            photo: '/assets/images/testimonials/JorgeFigueroa.png'
          },
          location: 'Principal and Director - El Laboratorio, Boulder CO',
          text: "CitSci is best-in-class. A powerful online platform for communities and community scientists to elegantly collect, manage, and analyze data with scientific integrity."
        },
        {
          id: 3,
          author: {
            firstName: 'Hope',
            lastName: 'Braithwaite',
            photo: '/assets/images/testimonials/HopeBraithwaite.png'
          },
          location: 'Water Quality Program Coordinator - Utah Water Watch',
          text: "I'm not sure what I would do without CitSci. They make coordinating projects a breeze. I can manage my sites and assign volunteers all in the same place."
        }
      ],
      baseURL: "",
    }

  },

  created() {
    this.reset()
    this.sendDataToHeader(this.headerData, this)
    this.$emit('is-homepage', true)
    //baseURL
  },

  mounted() {

    // Top 3 projects with most members are fetched, use results from project list store if present to prevent additional server calls
    if (!this.projects || this.projects.length === 0) {
      this.baseURL = window.location.origin;
      if (this.baseURL==='https://citsci.org') {
        //this.getFeaturedProjects({ urlParams: '?id[]=1aca74a9-8bd4-4ee7-aa41-d12488ad8eb2&id[]=fe1c04b6-8486-4014-8f8e-f11d91bef952&id[]=89b16d94-1f67-4eaa-823b-aae65aa05181' })

        // Used during Leave No Trash event May 2024
        //this.getFeaturedProjects({ urlParams: '?id[]=1ae44bca-d810-443b-aedb-6d1ef62e5e44&id[]=8ca12cef-9b2b-4138-9760-980133fe3041&id[]=f5e90d4a-3ffd-47d5-ae1e-a75051650b0a' })

        // Original list upt May 2024
        // this.getFeaturedProjects({ urlParams: '?id[]=9724a020-3394-45f2-ad8f-202f9c2529de&id[]=3e2b9d9c-5d3f-4bc7-83a8-f303904ead8c&id[]=89b16d94-1f67-4eaa-823b-aae65aa05181' })

        // New list since June 2024 <UWW, BEW, TLI Perennial Atlas>
        this.getFeaturedProjects({ urlParams: '?id[]=9724a020-3394-45f2-ad8f-202f9c2529de&id[]=3e2b9d9c-5d3f-4bc7-83a8-f303904ead8c&id[]=fecd6bc0-45ef-43f5-a10d-2fa298bf2757' })
      }
      else {
        // for alpha and beta <UWW, BEW, TLI Sainfoin>
        this.getFeaturedProjects({ urlParams: '?id[]=9724a020-3394-45f2-ad8f-202f9c2529de&id[]=3e2b9d9c-5d3f-4bc7-83a8-f303904ead8c&id[]=89b16d94-1f67-4eaa-823b-aae65aa05181' })
      }
    }

    // Fetch the 4 most recent observations with images
    this.observationCardArr = []
    this.getAllObservationsList({ urlParams: '?order[observedAt]=desc&records.recordType=image&itemsPerPage=4' })
    this.getStats()
  },

  computed: {
    ...mapGetters({
      error: 'project/show/error',
      violations: 'project/show/violations',
      isLoadingStats: 'about/show/isLoading',
      stats: 'about/show/stats',
      observationCards: 'observation/list/allItems',
      isLoadingObservations: 'observation/list/isLoadingAll',
      featuredProjects: 'project/list/featuredProjects',
      isLoadingProjects: 'project/list/isLoading',
    }),

    // Computed getter for formatted observation cards
    formattedObservationCards() {

      // Return our formatted observation cards with images
      if (this.observationCards) {

        // Reset the array to prevent stale data
        this.observationCardArr = []

        let i = 0
        this.observationCards.forEach(obs => {
          let observationCardTemplate = {}
          let bFoundImg = false

          // set the observation id
          observationCardTemplate.id = obs.id

          // set the default observation image and then look for the first observation image
          observationCardTemplate.observationPicture = '/assets/images/generic_img.png'
          obs.records.forEach(rec => {
            if (rec.recordType === 'image' && rec.fileObject && rec.fileObject.path && !bFoundImg) {
              observationCardTemplate.observationPicture = rec.fileObject.path
              bFoundImg = true
            }
          })

          // set the observer's name
          observationCardTemplate.observerName = obs.observers[0].displayName

          // set the default observer profile image
          observationCardTemplate.observerPicture = '/assets/images/default_avatar.png'
          bFoundImg = false
          obs.observers.forEach(mem => {
            if (!bFoundImg) {
              observationCardTemplate.observerPicture = mem.picturePath
              bFoundImg = true
            }
          })

          // set the observed data
          observationCardTemplate.observedAt = obs.observedAt

          // set the project name and urlfield
          observationCardTemplate.projectName = obs.project.name
          observationCardTemplate.projectURL = obs.project.urlField

          // set the location id and name
          observationCardTemplate.locationId = obs.location.id
          observationCardTemplate.locationName = obs.location.name

          // Add the observation card to the array and increment the counter
          this.observationCardArr[i] = observationCardTemplate
          i++
        })
      }
      return this.observationCardArr

    }

  },

  methods: {
    ...mapActions({
      getStats: 'about/show/getStats',
      reset: 'about/show/reset',
      getFeaturedProjects: 'project/list/getFeaturedProjects',
      getAllObservationsList: 'observation/list/getAllObservationsList',
      joinToProject: 'project/show/joinToProject',
    }),

    addThousandsPoint(number) {
      // Fixes browser debugger error when number is undefined
      if (number == null)
        number = 0;

      number = number.toString()
      while (true) {
        var n2 = number.replace(/(\d)(\d{3})($|,|\.)/g, '$1,$2$3')
        if (number === n2) break
        number = n2
      }
      return number
    },

    onCardClick(evt, projectData) {
      evt.preventDefault();

      if (evt.target.getAttribute('class').indexOf('btn-join') !== -1) {
        if (JSON.parse(localStorage.getItem('sessionData')) == null) {
          this.$root.$emit('bv::show::modal', 'not-logged-in-modal');
          return;
        } else {
          this.joinToProject({ id: projectData.id });
          return;
        }
      }

      this.$router.push('/projects/' + projectData.urlField);
    },

    mouseOver() {
      // this.$refs.buttonTooltip1.$emit('open')
    }
  },

  watch: {
    violations(newViolationsVal) {
      if (typeof newViolationsVal == 'object' && newViolationsVal != null && newViolationsVal._error.length > 0) {
        delete newViolationsVal._error
      }
      return newViolationsVal
    },
  },

  beforeDestroy() {
    this.reset()
  }
}
