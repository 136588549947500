import fetch from '../../../../utils/fetch'
import statsModule from './stats'
import likesModule from './likes'
import locationsModule from './locations'
import zooniverseModule from './zooniverse'

const getInitialState = () => {
  return {
    isLoading: false,
    id: null,
    project: null,
    error: null,

    observationShowCard: false,
    observationPage: 1,
    observationItems: 10,
    observationTotal: 0,
    observationSearch: "",
    observationSortBy: "observedAt",
    observationSortDirection: "desc",
    observationSortDesc: true,

    locationShowCard: false,
    locationPage: 1,
    locationItems: 10,
    locationTotal: 0,
    locationSearch: "",
    locationSortBy: "name",
    locationSortDirection: "desc",
    locationSortDesc: false,

    resourceShowCard: false,
    resourcePage: 1,
    resourceItems: 10,
    resourceTotal: 0,
    resourceSearch: "",
    resourceSortBy: "updatedAt",
    resourceSortDirection: "desc",
    resourceSortDesc: true,

    forumPage: 1,
    forumItems: 10,
    forumTotal: 0,
    forumSearch: "",
    forumTag: null,
    forumHiddenPost: null,
    forumSortBy: "createdAt",
    forumSortDirection: "desc",
    forumSortDesc: true,
  }
}

const state = getInitialState();

const getters = {
    project(state) { return state.project },
    id(state) { return state.id },
    isLoading(state) { return state.isLoading },
    error(state) { return state.error },
    publishedDatasheets(state) {
      if (state.project !== null) {
        return state.project.datasheets.filter((datasheet) => {
          return datasheet.published;
        }).sort((a, b) => {
          let ad = a.name.toLowerCase(),
            bd = b.name.toLowerCase();
          if (ad < bd) return -1;
          if (ad > bd) return 1;
          return 0;
        });
      }
    },
    isPublicOrMember(state, getters, rootState, rootGetters) {
        if (!state.project || !state.id) { return false; }
        if (!state.project.isPrivate) { return true; }
        return (
            rootGetters['user/profile/hasSession'] &&
            rootGetters['user/profile/membershipMap'][state.id] &&
            rootGetters['user/profile/membershipMap'][state.id].isMember
        );
    },
    isMember(state, getters, rootState, rootGetters) {
        if (!state.project || !state.id) { return false }
        return (
            rootGetters['user/profile/hasSession'] &&
            rootGetters['user/profile/membershipMap'][state.id] &&
            rootGetters['user/profile/membershipMap'][state.id].isMember
        );
    },
    isManager(state, getters, rootState, rootGetters) {
        if (!state.project || !state.id) { return false }
        return (
            rootGetters['user/profile/hasSession'] &&
            rootGetters['user/profile/membershipMap'][state.id] &&
            rootGetters['user/profile/membershipMap'][state.id].isManager
        );
    },
    isPending(state, getters, rootState, rootGetters) {
        if (!state.project || !state.id) { return false }
        return (
            rootGetters['user/profile/hasSession'] &&
            rootGetters['user/profile/membershipMap'][state.id] &&
            rootGetters['user/profile/membershipMap'][state.id].isPending
        );
    },

  observationPage(state) {
    return state && state.observationPage
  },
  observationItems(state) {
    return state && state.observationItems
  },
  observationSearch(state) {
    return state && state.observationSearch
  },
  observationTotal(state) {
    return state && state.observationTotal
  },
  observationSortBy(state) {
    return state && state.observationSortBy
  },
  observationSortDirection(state) {
    return state && state.observationSortDirection
  },
  observationSortDesc(state) {
    return state && state.observationSortDesc
  },

  locationPage(state) {
    return state && state.locationPage
  },
  locationItems(state) {
    return state && state.locationItems
  },
  locationSearch(state) {
    return state && state.locationSearch
  },
  locationTotal(state) {
    return state && state.locationTotal
  },
  locationSortBy(state) {
    return state && state.locationSortBy
  },
  locationSortDirection(state) {
    return state && state.locationSortDirection
  },
  locationSortDesc(state) {
    return state && state.locationSortDesc
  },

  resourceShowCard(state) {
    return state && state.resourceShowCard
  },
  resourcePage(state) {
    return state && state.resourcePage
  },
  resourceItems(state) {
    return state && state.resourceItems
  },
  resourceSearch(state) {
    return state && state.resourceSearch
  },
  resourceTotal(state) {
    return state && state.resourceTotal
  },
  resourceSortBy(state) {
    return state && state.resourceSortBy
  },
  resourceSortDirection(state) {
    return state && state.resourceSortDirection
  },
  resourceSortDesc(state) {
    return state && state.resourceSortDesc
  },

  forumPage(state) {
    return state && state.forumPage
  },
  forumItems(state) {
    return state && state.forumItems
  },
  forumSearch(state) {
    return state && state.forumSearch
  },
  forumTag(state) {
    return state && state.forumTag
  },
  forumHiddenPost(state) {
    return state && state.forumHiddenPost
  },
  forumTotal(state) {
    return state && state.forumTotal
  },
  forumSortBy(state) {
    return state && state.forumSortBy
  },
  forumSortDirection(state) {
    return state && state.forumSortDirection
  },
  forumSortDesc(state) {
    return state && state.forumSortDesc
  },
}

const actions = {
  /**
   * @function get - Retrieve Project data
   * @param commit
   * @param dispatch
   * @param projectName {String}
   * @returns {Promise<T | never> | * | *}
   */
  get({commit, dispatch}, projectName) {
    commit(types.RESET)
    commit(types.SET_PROJECT_LOADING);
    return fetch('/projects/' + projectName + '/id')
      .then(response => response.json())
      .then((data) => {
        const id = data.id;
        commit(types.SET_PROJECT_ID, id)
        return fetch('/projects/' + id)
          .then(response => response.json())
          .then((project) => {
            commit(types.SET_PROJECT, project)
            // Call to fetch stats for this project
            dispatch('project/get/stats/get', {}, {root: true});
            dispatch('project/get/locations/get', {}, { root: true });
            dispatch('project/photo_observations/get', 4, { root: true });
            commit('project/get/likes/SET_PROJECT_LIKES', project.totalLikes, {root: true})
            commit('project/get/zooniverse/SET_HISTORY', project.datasheets, {root: true})
          })
          .catch((error) => {
            commit(types.SET_PROJECT_ERROR, error.message)
          })
      })
      .catch((error) => {
        commit(types.SET_PROJECT_ERROR, error.message)
      })
  },
  refresh({commit, state}) {
    commit(types.SET_PROJECT_LOADING);
    //dispatch('project/get/refresh', null, { root: true }) // -> 'someOtherAction'

    return fetch('/projects/' + state.id)
      .then(response => response.json())
      .then((project) => {
        commit(types.SET_PROJECT, project)
        dispatch('project/get/stats/get', {}, {root: true})
        dispatch('project/get/locations/get', {}, { root: true });
        dispatch('project/photo_observations/get', 4, { root: true });
        commit('project/get/likes/SET_PROJECT_LIKES', project.totalLikes, {root: true})
        commit('project/get/zooniverse/SET_HISTORY', project.datasheets, {root: true})
      })
      .catch((error) => {
        commit(types.SET_PROJECT_ERROR, error.message)
      })
  },
  refreshStats({dispatch}) {
    dispatch('project/get/stats/get', {}, {root: true})
  },
  refreshLocations({dispatch}) {
    dispatch('project/get/locations/get', {}, { root: true });
  },

  getProjectIdByName(projectName) {
    return fetch('/projects/' + projectName + '/id')
      .then(response => response.json())
      .then((data) => {
        return data !== undefined;
      })
      .catch((e) => {
        return false
      });
  },

  setObservationTablePage({commit}, page) {
    commit(types.SET_OBS_PAGE, page)
  },
  setObservationTableItems({commit}, items) {
    commit(types.SET_OBS_ITEMS, items)
  },
  setObservationTableTotal({commit}, total) {
    commit(types.SET_OBS_TOTAL, total)
  },
  setObservationTableSearch({commit}, search) {
    commit(types.SET_OBS_SEARCH, search)
  },
  setObservationTableSortBy({commit}, sortBy) {
    commit(types.SET_OBS_SORT_BY, sortBy)
  },
  setObservationTableSortDirection({commit}, sortDirection) {
    commit(types.SET_OBS_SORT_DIRECTION, sortDirection)
  },
  setObservationTableSortDesc({commit}, sortDesc) {
    commit(types.SET_OBS_SORT_DESC, sortDesc)
  },

  setLocationTablePage({commit}, page) {
    commit(types.SET_LOC_PAGE, page)
  },
  setLocationTableItems({commit}, items) {
    commit(types.SET_LOC_ITEMS, items)
  },
  setLocationTableTotal({commit}, total) {
    commit(types.SET_LOC_TOTAL, total)
  },
  setLocationTableSearch({commit}, search) {
    commit(types.SET_LOC_SEARCH, search)
  },
  setLocationTableSortBy({commit}, sortBy) {
    commit(types.SET_LOC_SORT_BY, sortBy)
  },
  setLocationTableSortDirection({commit}, sortDirection) {
    commit(types.SET_LOC_SORT_DIRECTION, sortDirection)
  },
  setLocationTableSortDesc({commit}, sortDesc) {
    commit(types.SET_LOC_SORT_DESC, sortDesc)
  },

  setResourceShowCard({commit}, isCard) {
    commit(types.SET_RES_CARD, isCard)
  },
  setResourceTablePage({commit}, page) {
    commit(types.SET_RES_PAGE, page)
  },
  setResourceTableItems({commit}, items) {
    commit(types.SET_RES_ITEMS, items)
  },
  setResourceTableTotal({commit}, total) {
    commit(types.SET_RES_TOTAL, total)
  },
  setResourceTableSearch({commit}, search) {
    commit(types.SET_RES_SEARCH, search)
  },
  setResourceTableSortBy({commit}, sortBy) {
    commit(types.SET_RES_SORT_BY, sortBy)
  },
  setResourceTableSortDirection({commit}, sortDirection) {
    commit(types.SET_RES_SORT_DIRECTION, sortDirection)
  },
  setResourceTableSortDesc({commit}, sortDesc) {
    commit(types.SET_RES_SORT_DESC, sortDesc)
  },

  setForumTablePage({commit}, page) {
    commit(types.SET_FORUM_PAGE, page)
  },
  setForumTableItems({commit}, items) {
    commit(types.SET_FORUM_ITEMS, items)
  },
  setForumTableTotal({commit}, total) {
    commit(types.SET_FORUM_TOTAL, total)
  },
  setForumTableSearch({commit}, search) {
    commit(types.SET_FORUM_SEARCH, search)
  },
  setForumTableTag({commit}, tag) {
    commit(types.SET_FORUM_TAG, tag)
  },
  setForumHiddenPost({commit}, hide) {
    console.log('set hidden', hide)
    commit(types.SET_FORUM_HIDDEN_POST, hide)
  },
  setForumTableSortBy({commit}, sortBy) {
    commit(types.SET_FORUM_SORT_BY, sortBy)
  },
  setForumTableSortDirection({commit}, sortDirection) {
    commit(types.SET_FORUM_SORT_DIRECTION, sortDirection)
  },
  setForumTableSortDesc({commit}, sortDesc) {
    commit(types.SET_FORUM_SORT_DESC, sortDesc)
  },
}

const types = {
  SET_PROJECT_LOADING: 'SET_PROJECT_LOADING',
  SET_PROJECT_ID: 'SET_PROJECT_ID',
  SET_PROJECT: 'SET_PROJECT',
  SET_PROJECT_ERROR: 'SET_PROJECT_ERROR',

  SET_OBS_PAGE: 'SET_OBS_PAGE',
  SET_OBS_ITEMS: 'SET_OBS_ITEMS',
  SET_OBS_TOTAL: 'SET_OBS_TOTAL',
  SET_OBS_SEARCH: 'SET_OBS_SEARCH',
  SET_OBS_SORT_BY: 'SET_OBS_SORT_BY',
  SET_OBS_SORT_DIRECTION: 'SET_OBS_SORT_DIRECTION',
  SET_OBS_SORT_DESC: 'SET_OBS_SORT_DESC',

  SET_LOC_PAGE: 'SET_LOC_PAGE',
  SET_LOC_ITEMS: 'SET_LOC_ITEMS',
  SET_LOC_TOTAL: 'SET_LOC_TOTAL',
  SET_LOC_SEARCH: 'SET_LOC_SEARCH',
  SET_LOC_SORT_BY: 'SET_LOC_SORT_BY',
  SET_LOC_SORT_DIRECTION: 'SET_LOC_SORT_DIRECTION',
  SET_LOC_SORT_DESC: 'SET_LOC_SORT_DESC',

  SET_RES_CARD: 'SET_RES_CARD',
  SET_RES_PAGE: 'SET_RES_PAGE',
  SET_RES_ITEMS: 'SET_RES_ITEMS',
  SET_RES_TOTAL: 'SET_RES_TOTAL',
  SET_RES_SEARCH: 'SET_RES_SEARCH',
  SET_RES_SORT_BY: 'SET_RES_SORT_BY',
  SET_RES_SORT_DIRECTION: 'SET_RES_SORT_DIRECTION',
  SET_RES_SORT_DESC: 'SET_RES_SORT_DESC',

  SET_FORUM_PAGE: 'SET_FORUM_PAGE',
  SET_FORUM_ITEMS: 'SET_FORUM_ITEMS',
  SET_FORUM_TOTAL: 'SET_FORUM_TOTAL',
  SET_FORUM_SEARCH: 'SET_FORUM_SEARCH',
  SET_FORUM_TAG: 'SET_FORUM_TAG',
  SET_FORUM_HIDDEN_POST: 'SET_FORUM_HIDDEN_POST',
  SET_FORUM_SORT_BY: 'SET_FORUM_SORT_BY',
  SET_FORUM_SORT_DIRECTION: 'SET_FORUM_SORT_DIRECTION',
  SET_FORUM_SORT_DESC: 'SET_FORUM_SORT_DESC',

  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PROJECT_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_PROJECT_ID](state, id) {
    state.id = id
  },
  [types.SET_PROJECT](state, project) {
    state.project = project
    state.isLoading = false
  },
  [types.SET_PROJECT_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },

  [types.SET_OBS_PAGE](state, page) {
    state.observationPage = page;
  },
  [types.SET_OBS_ITEMS](state, items) {
    state.observationItems = items;
  },
  [types.SET_OBS_TOTAL](state, total) {
    state.observationTotal = total;
  },
  [types.SET_OBS_SEARCH](state, search) {
    state.observationSearch = search;
  },
  [types.SET_OBS_SORT_BY](state, sortBy) {
    state.observationSortBy = sortBy;
  },
  [types.SET_OBS_SORT_DIRECTION](state, sortDirection) {
    state.observationSortDirection = sortDirection;
  },
  [types.SET_OBS_SORT_DESC](state, sortDesc) {
    state.observationSortDesc = sortDesc;
  },

  [types.SET_LOC_PAGE](state, page) {
    state.locationPage = page;
  },
  [types.SET_LOC_ITEMS](state, items) {
    state.locationItems = items;
  },
  [types.SET_LOC_TOTAL](state, total) {
    state.locationTotal = total;
  },
  [types.SET_LOC_SEARCH](state, search) {
    state.locationSearch = search;
  },
  [types.SET_LOC_SORT_BY](state, sortBy) {
    state.locationSortBy = sortBy;
  },
  [types.SET_LOC_SORT_DIRECTION](state, sortDirection) {
    state.locationSortDirection = sortDirection;
  },
  [types.SET_LOC_SORT_DESC](state, sortDesc) {
    state.locationSortDesc = sortDesc;
  },


  [types.SET_RES_CARD](state, isCard) {
    state.resourceShowCard = isCard;
  },
  [types.SET_RES_PAGE](state, page) {
    state.resourcePage = page;
  },
  [types.SET_RES_ITEMS](state, items) {
    state.resourceItems = items;
  },
  [types.SET_RES_TOTAL](state, total) {
    state.resourceTotal = total;
  },
  [types.SET_RES_SEARCH](state, search) {
    state.resourceSearch = search;
  },
  [types.SET_RES_SORT_BY](state, sortBy) {
    state.resourceSortBy = sortBy;
  },
  [types.SET_RES_SORT_DIRECTION](state, sortDirection) {
    state.resourceSortDirection = sortDirection;
  },
  [types.SET_RES_SORT_DESC](state, sortDesc) {
    state.resourceSortDesc = sortDesc;
  },

  [types.SET_FORUM_PAGE](state, page) {
    state.forumPage = page;
  },
  [types.SET_FORUM_ITEMS](state, items) {
    state.forumItems = items;
  },
  [types.SET_FORUM_TOTAL](state, total) {
    state.forumTotal = total;
  },
  [types.SET_FORUM_SEARCH](state, search) {
    state.forumSearch = search;
  },
  [types.SET_FORUM_TAG](state, tag) {
    state.forumTag = tag;
  },
   [types.SET_FORUM_HIDDEN_POST](state, hide) {
    state.forumHiddenPost = hide;
  },
  [types.SET_FORUM_SORT_BY](state, sortBy) {
    state.forumSortBy = sortBy;
  },
  [types.SET_FORUM_SORT_DIRECTION](state, sortDirection) {
    state.forumSortDirection = sortDirection;
  },
  [types.SET_FORUM_SORT_DESC](state, sortDesc) {
    state.forumSortDesc = sortDesc;
  },

  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
    modules: {
        stats: statsModule,
        likes: likesModule,
        locations: locationsModule,
        zooniverse: zooniverseModule,
    }
}
