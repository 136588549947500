<template>
  <div v-if="Object.keys(projects).length > 0" class="mx-auto section bg-green">
    <b-row align-v="center">
      <b-col cols="2">
        <h3 class="text-white">Projects</h3>
        <span class="text-white">affiliated with this Hub</span>
      </b-col>
      <b-col lg="10">
        <PhotoProjectCard :projects="projects"> </PhotoProjectCard>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PhotoProjectCard from "../../../layout/PhotoProjectCard";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PhotoProjectCard
  },
  computed: {
    ...mapGetters({
      projects: "organization/get/projects"
    })
  }
};
</script>
<style lang=""></style>
